import React, { useEffect, useState } from 'react'
import edit from '../../../Assets/Images/edit.svg';
import { Link } from 'react-router-dom';
import { useHome } from '../../../Context/HomeContext';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function AllPagesBannersTable() {
  const { getPagesBanners, isPagesBanners } = useHome();

  useEffect(() => {
    getPagesBanners();
  }, []);

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      {!isPagesBanners && <LoadingOverlay />} {/* Display loading overlay */}
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>SN</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '40%' }}>Image</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Page</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isPagesBanners?.data?.map((Page, index) => (
            <tr key={index}>
              <td className='p-5 text-start' style={{ width: '30%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '40%' }}><img src={Page.image_url} alt="" className='w-[250px] h-[125px] rounded-xl' /></td>
              <td className='p-5 text-start' style={{ width: '20%' }}>{Page.page}</td>
              <td className='p-5 text-center' style={{ width: '20%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/Pages/EditPageBanner/${Page.id}`}>
                    <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllPagesBannersTable