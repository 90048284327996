import React, { useState } from "react";
import photo from "../../../Assets/Images/Vector.svg";
import add from "../../../Assets/Images/add.png";
import remove from "../../../Assets/Images/remove.png";
import { useNavigate } from "react-router-dom";
import { newBaseUrl } from "../../../Consts";
import axios from "axios";
import { useProjects } from "../../../Context/ProjectsContext";
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from "../../LoadingOverlay/LoadingOverlay";

function NewProjectForm() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const navigate = useNavigate();
    const { getProjectsCategory, isProjectsCategory } = useProjects();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [inputs, setInputs] = useState([{ text: '', image: null }]); // Initialize with one empty inp ut
    const [loading, setLoading] = useState(false);

    const handleInputChange = (index, event) => {
        const { name, value, files } = event.target;
        const newInputs = [...inputs];

        if (files && files.length > 0) {
            const file = files[0]; // Only the first file

            // Validate file size
            const maxSizeInBytes = 512 * 1024; // 512KB
            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 512KB. Please upload a smaller file.");
                return; // Exit without updating the state
            }

            // Validate file type (jpg, jpeg, png)
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPG, JPEG, or PNG files are allowed.");
                return; // Exit without updating the state
            }

            newInputs[index][name] = file;
        } else {
            // Check if the text input is empty or the file input is not selected
            if (value === "" && (!files || files.length === 0)) {
                // Clear both text and file input if they are empty
                newInputs[index][name] = name === "text" ? "" : null; // Clear the respective field
            } else {
                newInputs[index][name] = value;
            }
        }

        setInputs(newInputs);
    };

    const handleAddInput = () => {
        setInputs([...inputs, { text: '', image: null }]);
    };

    const handleRemoveInput = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);
    };

    const handleFilesChange = (event) => {
        const files = Array.from(event.target.files); // Convert FileList to Array
        const maxSizeInBytes = 512 * 1024; // 512KB

        // Allowed formats
        const allowedFormats = ["image/jpeg", "image/png"];

        // Filter valid files
        const validFiles = files.filter(
            (file) => allowedFormats.includes(file.type) && file.size <= maxSizeInBytes
        );

        // Notify user about invalid files
        if (validFiles.length !== files.length) {
            alert("Only JPG, JPEG, PNG files under 512KB are allowed. Some files were not selected.");
        }

        // Update state with valid files only
        setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

        event.target.value = null; // Reset input for consecutive uploads
    };

    const handleRemoveImage = (indexToRemove) => {
        const updatedFiles = selectedFiles.filter((file, index) => index !== indexToRemove);
        setSelectedFiles(updatedFiles);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 512 * 1024; // 512KB

            // Validate file size
            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 512KB. Please upload a smaller file.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // Validate file type (jpg, jpeg, png)
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPG, JPEG, or PNG files are allowed.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // If all validations pass, update state with selected file
            setSelectedFile(file);
        }
    };


    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'lang_id': selectedLanguage == 0 ? null : selectedLanguage,
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'project_owner': document.getElementById('project_owner').value,
                'area': document.getElementById('area').value,
                'year': document.getElementById('year').value,
                'project_cat_id': selectedCategory == 0 ? null : selectedCategory,
                'status': selectedStatus,
                'image': selectedFile,
            };

            if (selectedFiles.length > 0) {
                body['gallery_images'] = selectedFiles;
            }

            if (inputs.length > 0) {
                body['descriptions'] = inputs.filter(input => input.text !== '' || input.image !== null);
            }

            const response = await axios.post(`${newBaseUrl}/api/store/project`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });

            toast.success('Project added successfully!', {
                onClose: () => {
                    navigate(`/Projects/AllProjects/${selectedLanguage == 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });

        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }
    };


    return (
        <div>
            <ToastContainer autoClose={5000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {loading && <LoadingOverlay />} {/* Display loading overlay */}
            <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
                <div className="flex flex-col gap-5 px-[50px]">
                    <div
                        className="flex rounded-lg bg-[#F2F4F7]"
                    >
                        <label
                            htmlFor="fileInput"
                            className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                        >
                            <img
                                src={selectedFile == null ? photo : URL.createObjectURL(selectedFile)}
                                alt="File Upload"
                                className={selectedFile == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

                            />
                            <input
                                type="file"
                                id="fileInput"
                                accept="image/jpeg, image/png" // Restrict selection to jpg, jpeg, png
                                className="hidden"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>
                    <div>
                        <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                            disabled={loading}
                        >
                            Save
                        </button>
                    </div>
                </div>
                <div className="scrollable-content px-[50px]" style={{ width: '100%' }}>
                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Language <span className="text-red-500">*</span>
                    </label>
                    <select
                        name="Language"
                        id="language"
                        value={selectedLanguage}
                        onChange={(e) => {
                            setSelectedLanguage(e.target.value);
                            setSelectedCategory('')
                            getProjectsCategory(e.target.value === '1' ? 'en' : 'ar'); // Corrected condition
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value='0'>Select Language</option>
                        <option value="1">English</option>
                        <option value="2">Arabic</option>
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Title <span className="text-red-500">*</span>
                    </label>

                    <input
                        type={'text'}
                        id={'title'}
                        name={'Title'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Project Owner <span className="text-red-500">*</span>
                    </label>

                    <input
                        type={'text'}
                        id={'project_owner'}
                        name={'projectOwner'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type title here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Area <span className="text-red-500">*</span>
                    </label>

                    <input
                        type={'text'}
                        id={'area'}
                        name={'Area'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Area here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Year <span className="text-red-500">*</span>
                    </label>

                    <input
                        type={'text'}
                        id={'year'}
                        name={'Year'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type Year here`}
                        style={{
                            paddingBottom: "8px",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Category <span className="text-red-500">*</span>
                    </label>

                    <select
                        name="category"
                        id="category"
                        value={selectedCategory}
                        onChange={(e) => {
                            setSelectedCategory(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value='0'>Select Category</option>
                        {isProjectsCategory?.data?.map((Project, index) => (
                            <option key={Project.id} value={Project.id}>{Project.name}</option>
                        ))}
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Excerpt <span className="text-red-500">*</span>
                    </label>

                    <textarea
                        type={'text'}
                        id={'excerpt'}
                        name={'Excerpt'}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                        placeholder={`Type excerpt here`}
                        style={{
                            height: "80px",
                            paddingBottom: "3rem",
                        }}
                    />

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Status <span className="text-red-500">*</span>
                    </label>

                    <select
                        name="Status"
                        id="status"
                        value={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(e.target.value);
                        }}
                        className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                    >
                        <option value="1">Publish</option>
                        <option value="2">Draft</option>
                    </select>

                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Gallery
                    </label>

                    <div className="grid grid-cols-6 gap-y-5 mb-2">
                        {/* File Input for Multi-Select */}
                        <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                            <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                <img
                                    src={photo}
                                    alt="Add File"
                                    className={"w-[75px] h-[75px]"}
                                />
                                <input
                                    type="file"
                                    accept="image/jpeg, image/png" // Restrict selection to jpg, jpeg, png
                                    className="hidden"
                                    multiple // Allow multiple file selection
                                    onChange={handleFilesChange}
                                />
                            </label>
                        </div>

                        {/* Display Selected Files */}
                        {selectedFiles?.map((file, index) => (
                            <div
                                className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]"
                                key={index}
                            >
                                <div className="relative">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt="File Upload"
                                        className={"rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                    />
                                    <button
                                        className="absolute top-0 left-0 bg-transparent rounded-full p-1 m-2"
                                        onClick={() => handleRemoveImage(index)}
                                    >
                                        <img src={remove} alt="Remove" className="w-4 h-4" />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>


                    <label
                        className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                    >
                        Description
                    </label>
                    <div>
                        {inputs.map((input, index) => (
                            <div key={index} className="flex flex-row items-center justify-center gap-5">
                                <textarea
                                    type={'text'}
                                    name="text"
                                    value={input.text}
                                    onChange={(event) => handleInputChange(index, event)}
                                    className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                                    placeholder={`Type text here`}
                                    style={{
                                        height: "150px",
                                        paddingBottom: "7rem",
                                    }}
                                />
                                <div className="flex items-center w-[150px] h-[150px] lg:w-[150px] lg:h-[150px] justify-center rounded-lg bg-[#F2F4F7]">
                                    <label className="flex items-center justify-center cursor-pointer w-[150px] h-[150px]">
                                        <img
                                            src={input.image == null ? photo : URL.createObjectURL(input.image)}
                                            alt="File Upload"
                                            className={input.image == null ? "w-[75px] h-[75px]" : "rounded-lg w-[150px] h-[150px] lg:w-[150px] lg:h-[150px]"}
                                        />
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png" // Restrict selection to jpg, jpeg, png
                                            name="image"
                                            className="hidden"
                                            onChange={(event) => handleInputChange(index, event)}
                                        />
                                    </label>
                                </div>

                                {index != 0 ? <button className="rounded-lg bg-[#AF1F24] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                    onClick={() => handleRemoveInput(index)} >-</button> : <button
                                        className="rounded-lg bg-[#148342] text-[#fff] w-[50px] h-[50px] text-[25px] flex items-center justify-center cursor-pointer"
                                        onClick={handleAddInput}>+</button>}
                            </div>

                        ))}

                    </div>

                </div>
            </div>
        </div>
    )
}
export default NewProjectForm;