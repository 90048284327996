import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import upload from "../../../Assets/Images/upload.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function SubBrandForm() {
  const [SelectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const { getProductsBrand, isProductsBrand } = useProducts();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
        const maxSizeInBytes = 512 * 1024; // 512KB

        // Validate file size
        if (file.size > maxSizeInBytes) {
            alert("File size exceeds 512KB. Please upload a smaller file.");
            event.target.value = null; // Reset input field
            return; // Exit the function
        }

        // Validate file type (jpg, jpeg, png)
        const allowedTypes = ["image/jpeg", "image/png"];
        if (!allowedTypes.includes(file.type)) {
            alert("Only JPG, JPEG, or PNG files are allowed.");
            event.target.value = null; // Reset input field
            return; // Exit the function
        }

        // If all validations pass, update state with selected file
        setSelectedImage(file);
    }
};

const handleFileChange = (event) => {
  const file = event.target.files[0];

  if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024; // 512KB

      // Validate file size
      if (file.size > maxSizeInBytes) {
          alert("File size exceeds 2MB. Please upload a smaller file.");
          event.target.value = null; // Reset input field
          return; // Exit the function
      }

      // Validate file type (jpg, jpeg, png)
      const allowedTypes = ["application/pdf"];
      if (!allowedTypes.includes(file.type)) {
          alert("Only PDF files are allowed.");
          event.target.value = null; // Reset input field
          return; // Exit the function
      }

      // If all validations pass, update state with selected file
      setSelectedFile(file);
  }
};

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      const body = {
        'lang_id': selectedLanguage == 0 ? null : selectedLanguage,
        'name': document.getElementById('name').value,
        'brand_id': selectedBrand,
        'status': selectedStatus,
        'image': SelectedImage,
        'catalog': selectedFile
      };
      const response = await axios.post(`${newBaseUrl}/api/store/products-subbrand`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      });
      toast.success('Products Sub Brand added successfully!', {
        onClose: () => {
          navigate(`/Products/SubBrands/${selectedLanguage == 1 ? "en" : "ar"}`, { replace: true });
          setLoading(false);
        },
      });
      // console.log(res);
    } catch (err) {
      // Check if there are validation errors in the response
      if (err.response && err.response.data && err.response.data.errors) {
        // Loop through each error message and display it
        const errors = err.response.data.errors; // Assuming `errors` is an object or array
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((message) => {
              toast.error(message); // Display each error message
            });
          }
        }
      } else if (err.response && err.response.data && err.response.data.message) {
        // Display a single error message if available
        toast.error(err.response.data.message);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
      setLoading(false)
    }
  };

  return (
    <div>
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
      {loading && <LoadingOverlay />} {/* Display loading overlay */}
      <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
      <div className="flex flex-col gap-5 px-[50px]">
        <div
          className="flex rounded-lg bg-[#F2F4F7]"
        >
          <label
            htmlFor="imageInput"
            className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
          >
            <img
              src={SelectedImage == null ? photo : URL.createObjectURL(SelectedImage)}
              alt="File Upload"
              className={SelectedImage == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}

            />
            <input
              type="file"
              id="imageInput"
              className="hidden"
              accept="image/jpeg, image/png"
              onChange={handleImageChange}
            />
          </label>
        </div>
        <div
          className="flex flex-col rounded-lg bg-[#F2F4F7] w-[200px]"
        >
          <label
            htmlFor="fileInput"
            className="flex flex-row items-center justify-evenly cursor-pointer w-[200px] h-[50px]"
          >

            <h3>Upload Catalog</h3>

            <img
              src={upload}
              alt="File Upload"
              className={"w-[50px] h-[50px] py-2"}

            />
            <input
              type="file"
              id="fileInput"
              className="hidden"
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </label>
          <h3>{selectedFile == null ? null : `${selectedFile.name.substring(0, 20)}`}</h3>
        </div>
        <div>
          <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
            onClick={handleSaveClick}
          >
            Save
          </button>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <label
          className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
        >
          Language <span className="text-red-500">*</span>
        </label>
        <select
          name="Language"
          id="language"
          value={selectedLanguage}
          onChange={(e) => {
            setSelectedLanguage(e.target.value);
            setSelectedBrand('')
            getProductsBrand(e.target.value === '1' ? 'en' : 'ar'); // Corrected condition
            console.log(e.target.value); // Log the current value from the event
          }}
          className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
        >
          <option value='0'>Select Language</option>
          <option value="1">English</option>
          <option value="2">Arabic</option>
        </select>

        <label
          className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
        >
          Name <span className="text-red-500">*</span>
        </label>

        <input
          type={'text'}
          id={'name'}
          name={'Name'}
          className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
          placeholder={`Type name here`}
          style={{
            paddingBottom: "8px",
          }}
        />

        <label
          className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
        >
          Brand <span className="text-red-500">*</span>
        </label>

        <select
          name="Brand"
          id="Brand"
          value={selectedBrand}
          onChange={(e) => {
            setSelectedBrand(e.target.value);
          }}
          className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
        >
          <option value='0'>Select Brand</option>
          {isProductsBrand?.data?.map((Brand, index) => (
            <option key={Brand.id} value={Brand.id}>{Brand.name}</option>
          ))}
        </select>

        <label
          className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
        >
          Status <span className="text-red-500">*</span>
        </label>

        <select
          name="Status"
          id="status"
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
          className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
        >
          <option value="1">Publish</option>
          <option value="2">Draft</option>
        </select>
      </div>
    </div>
    </div>
  )
}

export default SubBrandForm