import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const CareersContext = createContext();
export const useCareers = () => {
    return useContext(CareersContext);
};

export default function CareersContextProvider({ children }) {

    const [isCareers, setIsCareers] = useState()
    const [isCareerById, setIsCareerById] = useState()

    async function getCareers(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/careers`, { headers });
            setIsCareers(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getCareerById(id) {
        setIsCareerById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/career/${id}`, { headers });
            setIsCareerById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteCareer(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/career/${id}`, { headers });
            // setIsCareers(res?.data);
            // console.log(res?.data?.data);
            getCareers(lang)
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <CareersContext.Provider value={{
            getCareers, getCareerById, deleteCareer, isCareers, isCareerById
        }}>
            {children}
        </CareersContext.Provider>
    );
}
