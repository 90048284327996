import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import upload from "../../../Assets/Images/upload.png";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function EditSubBrandForm() {
    const [SelectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(1);
    const { getProductsSubBrandById, isProductsSubBrandById } = useProducts();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 512 * 1024; // 512KB

            // Validate file size
            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 512KB. Please upload a smaller file.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // Validate file type (jpg, jpeg, png)
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPG, JPEG, or PNG files are allowed.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // If all validations pass, update state with selected file
            setSelectedImage(file);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 2 * 1024 * 1024; // 512KB

            // Validate file size
            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 2MB. Please upload a smaller file.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // Validate file type (jpg, jpeg, png)
            const allowedTypes = ["application/pdf"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only PDF files are allowed.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // If all validations pass, update state with selected file
            setSelectedFile(file);
        }
    };

    useEffect(() => {
        getProductsSubBrandById(id);
    }, []);

    useEffect(() => {
        if (isProductsSubBrandById?.data?.subbrand?.brand_id) {
            setSelectedBrand(isProductsSubBrandById?.data?.subbrand?.brand_id);
        }
        if (isProductsSubBrandById?.data?.subbrand?.status) {
            setSelectedStatus(isProductsSubBrandById?.data?.subbrand?.status);
        }
    }, [isProductsSubBrandById]);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'name': document.getElementById('name').value,
                'status': selectedStatus,
                'image': SelectedImage,
                'catalog': selectedFile,
                'brand_id': selectedBrand,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/products-subbrand/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Products Sub Brand added successfully!', {
                onClose: () => {
                    navigate(`/Products/SubBrands/${isProductsSubBrandById?.data?.subbrand?.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
            // console.log(res);
        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {(loading || !isProductsSubBrandById) ? <LoadingOverlay /> :
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
                    <div className="flex flex-col gap-5 px-[50px]">
                        <div
                            className="flex rounded-lg bg-[#F2F4F7]"
                        >
                            <label
                                htmlFor="imageInput"
                                className="flex flex-col items-center justify-center cursor-pointer w-[200px] h-[200px]"
                            >
                                <img
                                    src={isProductsSubBrandById?.data?.subbrand?.image_url != null && SelectedImage == null ? isProductsSubBrandById?.data?.subbrand?.image_url :
                                        isProductsSubBrandById?.data?.subbrand?.image_url != null && SelectedImage != null ? URL.createObjectURL(SelectedImage) : photo}
                                    alt="File Upload"
                                    className={SelectedImage == null && isProductsSubBrandById?.data?.subbrand?.image_url == null ? "w-[100px] h-[100px]" : "rounded-lg w-[200px] h-[200px]"}
                                />
                                <input
                                    type="file"
                                    id="imageInput"
                                    className="hidden"
                                    accept="image/jpeg, image/png"
                                    onChange={handleImageChange}
                                />
                            </label>
                        </div>
                        <div
                            className="flex flex-col rounded-lg bg-[#F2F4F7] w-[200px]"
                        >
                            <label
                                htmlFor="fileInput"
                                className="flex flex-row items-center justify-evenly cursor-pointer w-[200px] h-[50px]"
                            >

                                <h3>Upload Catalog</h3>

                                <img
                                    src={upload}
                                    alt="File Upload"
                                    className={"w-[50px] h-[50px] py-2"}

                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    accept="application/pdf"
                                    onChange={handleFileChange}
                                />
                            </label>
                            <Link to={isProductsSubBrandById?.data?.subbrand?.catalog_url} target="_blank">

                            <h3>
                                {selectedFile == null ?
                                    `${isProductsSubBrandById?.data?.subbrand?.catalog.substring(0, 15)}.` + isProductsSubBrandById?.data?.subbrand?.catalog.split('.').pop() :
                                    selectedFile != null ? `${selectedFile.name.substring(0, 15)}.` + selectedFile.name.split('.').pop() : null
                                }</h3>
                                </Link>
                        </div>
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="scrollable-content px-[50px]" style={{ width: '100%' }}>
                    <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Name <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'name'}
                            name={'Name'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type name here`}
                            defaultValue={isProductsSubBrandById?.data?.subbrand?.name}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Brand <span className="text-red-500">*</span>
                        </label>

                        <select
                            name="Brand"
                            id="brand"
                            value={selectedBrand}
                            onChange={(e) => {
                                setSelectedBrand(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            {isProductsSubBrandById?.data?.brands.map((brand, index) => (
                                <option key={brand.id} value={brand.id}>{brand.name}</option>
                            ))}
                        </select>

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status <span className="text-red-500">*</span>
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus != 0 ? selectedStatus : isProductsSubBrandById?.data?.status}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>

                    </div>
                </div>
            }
        </div>
    )
}

export default EditSubBrandForm