import React, { useEffect, useState } from 'react'
import photo from "../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function SubCategoryForm() {
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const { getProductsCategory, isProductsCategory } = useProducts();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSaveClick = async () => {
    setLoading(true);
    try {
      const body = {
        'lang_id': selectedLanguage == 0 ? null : selectedLanguage,
        'name': document.getElementById('name').value,
        'category_id': selectedCategory == 0 ? null : selectedCategory,
        'status': selectedStatus,
      };
      console.log(body)
      const response = await axios.post(`${newBaseUrl}/api/store/products-subcategory`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },
      });
      toast.success('Products Sub Category added successfully!', {
        onClose: () => {
          navigate(`/Products/SubCategories/${selectedLanguage == 1 ? "en" : "ar"}`, { replace: true });
          setLoading(false);
        },
      });
      // console.log(res);
    } catch (err) {
      // Check if there are validation errors in the response
      if (err.response && err.response.data && err.response.data.errors) {
        // Loop through each error message and display it
        const errors = err.response.data.errors; // Assuming `errors` is an object or array
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            errors[key].forEach((message) => {
              toast.error(message); // Display each error message
            });
          }
        }
      } else if (err.response && err.response.data && err.response.data.message) {
        // Display a single error message if available
        toast.error(err.response.data.message);
      } else {
        // Handle other errors
        toast.error("An unexpected error occurred.");
      }
      setLoading(false)
    }
  };

  return (
    <div>
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
      {loading && <LoadingOverlay />} {/* Display loading overlay */}
      <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[100px]">
        <div style={{ width: '100%' }}>
          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Language <span className="text-red-500">*</span>
          </label>
          <select
            name="Language"
            id="language"
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value);
              setSelectedCategory('')
              getProductsCategory(e.target.value === '1' ? 'en' : 'ar'); // Corrected condition
              console.log(e.target.value); // Log the current value from the event
            }}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
          >
            <option value='0'>Select Language</option>
            <option value="1">English</option>
            <option value="2">Arabic</option>
          </select>

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Name <span className="text-red-500">*</span>
          </label>

          <input
            type={'text'}
            id={'name'}
            name={'Name'}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
            placeholder={`Type name here`}
            style={{
              paddingBottom: "8px",
            }}
          />

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Category <span className="text-red-500">*</span>
          </label>

          <select
            name="Category"
            id="category"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
          >
            <option value='0'>Select Category</option>
            {isProductsCategory?.data?.map((category, index) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>

          <label
            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
          >
            Status <span className="text-red-500">*</span>
          </label>

          <select
            name="Status"
            id="status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
          >
            <option value="1">Publish</option>
            <option value="2">Draft</option>
          </select>
          <div>
            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
              onClick={handleSaveClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default SubCategoryForm