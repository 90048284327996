import React from "react";
import EditPageForm from "../Components/Pages/EditPage/EditPageForm";
import EditPageBannerForm from "../Components/Pages/EditPageBanner/EditPageBannerForm";

function EditPageBanner() {
  return (
    <div className="p-9">
      <EditPageBannerForm />
    </div>
  );
}

export default EditPageBanner;