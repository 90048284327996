import React, { useEffect, useState } from 'react';
import axios from "axios";
import { newBaseUrl } from "../../../Consts";
import { useNavigate, useParams } from 'react-router-dom';
import { useHome } from '../../../Context/HomeContext';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function EditPageForm() {
    const [editorData, setEditorData] = useState('');
    const { isPageById, getPageById } = useHome();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [editorLoaded, setEditorLoaded] = useState(false);

    useEffect(() => {
        getPageById(id);
    }, []);

    useEffect(() => {
        if (isPageById) {
            setEditorData(isPageById.data.content);
            setEditorLoaded(true); // Set editorLoaded to true when the content is available
        }
    }, [isPageById]);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${newBaseUrl}/api/update/page/${id}`, {
                content: editorData
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Page updated successfully!', {
                onClose: () => {
                    navigate(`/Pages/${isPageById?.data.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {(loading || !isPageById) ? <LoadingOverlay /> :
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px]">
                    <div className='scrollable-content px-[50px]' style={{ width: '100%' }}>
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">
                            Title
                        </label>
                        <input type={'text'} id={'title'} name={'Title'} disabled={true}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isPageById?.data?.title}
                            style={{ paddingBottom: "8px" }}
                        />
                        <label className="block text-start text-[16px] font-[400] font-[Cairo] pb-1">
                            Content <span className="text-red-500">*</span>
                        </label>
                        {editorLoaded && ( // Render CKEditor only when it's fully loaded
                            <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                }}
                            />
                        )}
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                                disabled={loading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditPageForm;
