import React, { useEffect } from 'react'
import delet from '../../../Assets/Images/delete.svg';
import edit from '../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import Pagination from '../../Pagination/Pagination';
import { useProjects } from '../../../Context/ProjectsContext';
import { Link, useParams } from 'react-router-dom';
import DeletModal from '../../DeleteModel/DeletModal';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
function CategoryTable() {
    const { getProjectsCategory, isProjectsCategory, deleteProjectsCategory } = useProjects();
    const { lang } = useParams();

    useEffect(() => {
        getProjectsCategory(lang);
    }, []);

    const handleDeleteProjectsCategory = async (id) => {
        try {
            await deleteProjectsCategory(id, lang);
            toast.success("Projects Category deleted successfully!");
        } catch (error) {
            if (error.response && error.response.data) {
                // Show the backend error message
                toast.error(error.response.data.message || "Error deleting Projects Category!");
            } else {
                // Generic fallback error
                toast.error("Error deleting Projects Category!");
            }
        }
    };

    return (
        <div className='bg-[#fff] rounded-2xl p-3'>
            <ToastContainer autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {!isProjectsCategory && <LoadingOverlay />} {/* Display loading overlay */}
            <table className='w-full border-collapse mb-6'>
                <thead>
                    <tr className='p-2'>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '10%' }}>SN</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Name</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Status</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {isProjectsCategory?.data?.map((service, index) => (
                        <tr key={index}>
                            <td className='p-5 text-start' style={{ width: '10%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
                            <td className='p-5 text-start' style={{ width: '20%' }}>{service.name}</td>
                            <td className='p-5 text-start' style={{ width: '20%' }}>
                                <div className={service.status == 1 ?
                                    'bg-[#0966AE] text-[#fff] p-2 w-[75px] rounded-md border text-center' :
                                    'bg-[#ffc107] p-2 w-[75px] rounded-md border text-center'}>
                                    {service.status == 1 ? "Publish" : "Draft"}
                                </div></td>
                            <td className='p-5 text-center' style={{ width: '20%' }}>
                                <div className='flex gap-2 items-center'>
                                    <Link to={`/Projects/EditCategory/${service.id}`}>
                                        <div className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border'>
                                            <img src={edit} alt="" />
                                        </div>
                                    </Link>
                                    <Popup
                                        trigger={
                                            <div className='bg-[#AF1F24] p-2 w-[40px] cursor-pointer rounded-md border'>
                                                <img src={delet} alt="" />
                                            </div>
                                        }
                                        modal
                                        nested
                                    >
                                        {(close) => (
                                            <div className="modal">
                                                <DeletModal close={close} id={service.id} onDelete={() => handleDeleteProjectsCategory(service.id)} />
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}


export default CategoryTable