import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"

export const HomeContext = createContext();
export const useHome = () => {
    return useContext(HomeContext);
};

export default function HomeContextProvider({ children }) {
    const [isBanner, setIsBanner] = useState()
    const [isBannerById, setIsBannerById] = useState()
    const [isPartner, setIsPartner] = useState()
    const [isPartnerById, setIsPartnerById] = useState()
    const [isCounter, setIsCounter] = useState()
    const [isCounterById, setIsCounterById] = useState()
    const [isSocialLinks, setIsSocialLinks] = useState()
    const [isSocialLinkById, setIsSocialLinkById] = useState()
    const [isPages, setIsPages] = useState()
    const [isPageById, setIsPageById] = useState()
    const [isPagesBanners, setIsPagesBanners] = useState()
    const [isPageBannerById, setIsPageBannerById] = useState()

    async function getBanner(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/banners`, { headers });
            setIsBanner(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getBannerById(id) {
        setIsBannerById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/banner/${id}`, { headers });
            setIsBannerById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteBanner(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/banner/${id}`, { headers });
            // setIsNews(res?.data);
            // console.log(res?.data?.data);
            getBanner(lang)
        } catch (err) {
            console.error(err);
        }
    }

    async function getPartner(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/partners`, { headers });
            setIsPartner(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPartnerById(id) {
        setIsPartnerById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/partner/${id}`, { headers });
            setIsPartnerById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deletePartner(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/partner/${id}`, { headers });
            // setIsNews(res?.data);
            // console.log(res?.data?.data);
            getPartner(lang)
        } catch (err) {
            console.error(err);
        }
    }

    async function getCounter(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/counters`, { headers });
            setIsCounter(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getCounterById(id) {
        setIsCounterById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/counter/${id}`, { headers });
            setIsCounterById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getSocialLinks(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/socialLinks`, { headers });
            setIsSocialLinks(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getSocialLinkById(id) {
        setIsSocialLinkById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/socialLink/${id}`, { headers });
            setIsSocialLinkById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPages(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/pages`, { headers });
            setIsPages(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPageById(id, lang) {
        setIsPageById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/page/${id}`, { headers });
            setIsPageById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPagesBanners() {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/pages-banners`, { headers });
            setIsPagesBanners(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getPageBannerById(id) {
        setIsPageBannerById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/page-banner/${id}`, { headers });
            setIsPageBannerById(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err); 
        }
    }

    return (
        <HomeContext.Provider value={{
            getBanner, getBannerById, isBanner, isBannerById, deleteBanner, getPartner, isPartner, getPartnerById, isPartnerById, deletePartner, getCounter, isCounter, getCounterById, isCounterById,
            getSocialLinks, isSocialLinks, getSocialLinkById, isSocialLinkById, getPages, isPages, getPageById, isPageById, getPageBannerById, isPageBannerById, getPagesBanners, isPagesBanners
        }}>
            {children}
        </HomeContext.Provider>
    );
}
