import React from "react";
import AllPagesBannersTable from "../Components/Pages/AllPagesBanners/PagesBannersTable";

function AllPagesBanners() {
  return (
    <div>
      <div className="p-10">
      <AllPagesBannersTable />
      </div>
    </div>
  );
}

export default AllPagesBanners;
