import React, { useEffect, useState } from 'react'
import delet from '../../../Assets/Images/delete.svg';
import edit from '../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import Pagination from '../../Pagination/Pagination';
import { Link, useParams } from 'react-router-dom';
import { useProducts } from '../../../Context/ProductsContext';
import DeletModal from '../../DeleteModel/DeletModal';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function AllProductsTable() {
  const { getProducts, isProducts, deleteProduct } = useProducts();
  const { lang } = useParams();

  useEffect(() => {
    getProducts(lang);
  }, []);

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProduct(id, lang);
      toast.success("Product deleted successfully!");
    } catch (error) {
      toast.error("Error deleting Product!");
      console.error("Error deleting Product:", error);
    }
  };

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      <ToastContainer autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
      {!isProducts && <LoadingOverlay />} {/* Display loading overlay */}
      <div className="scrollable-content overflow-auto max-h-[500px]">
        <table className='w-full border-collapse mb-6'>
          <thead className="sticky top-0 bg-[#fff] z-10">
            <tr className='p-2'>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '10%' }}>SN</th>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Image</th>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Name</th>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Category</th>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Status</th>
              <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {isProducts?.data?.map((Product, index) => (
              <tr key={index}>
                <td className='p-5 text-start' style={{ width: '10%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
                <td className='p-5 text-start' style={{ width: '20%' }}><img src={Product.image_url} alt="" className='w-[125px] h-[125px] rounded-xl' /></td>
                <td className='p-5 text-start' style={{ width: '20%' }}>{Product.title}</td>
                <td className='p-5 text-start' style={{ width: '20%' }}>{Product.category?.name}</td>
                <td className='p-5 text-start' style={{ width: '20%' }}>
                  <div className={Product.status == 1 ?
                    'bg-[#0966AE] text-[#fff] p-2 w-[75px] rounded-md border text-center' :
                    'bg-[#ffc107] p-2 w-[75px] rounded-md border text-center'}>
                    {Product.status == 1 ? "Publish" : "Draft"}
                  </div></td>
                <td className='p-5 text-center' style={{ width: '20%' }}>
                  <div className='flex gap-2 items-center'>
                    <Link to={`/Products/EditProduct/${Product.id}`}>
                      <div className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border'>
                        <img src={edit} alt="" />
                      </div>
                    </Link>
                    <Popup
                      trigger={
                        <div className='bg-[#AF1F24] p-2 w-[40px] cursor-pointer rounded-md border'>
                          <img src={delet} alt="" />
                        </div>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <DeletModal close={close} id={Product.id} onDelete={() => handleDeleteProduct(Product.id)} />
                        </div>
                      )}
                    </Popup>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllProductsTable