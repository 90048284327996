import React from 'react';

function LoadingOverlay() {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="spinner-border animate-spin w-12 h-12 border-4 border-white rounded-full"></div>
        </div>
    );
}

export default LoadingOverlay;
