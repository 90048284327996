import React, { useEffect, useState } from 'react'
import photo from "../../../../Assets/Images/Vector.svg";
import axios from "axios";
import { newBaseUrl } from "../../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useHome } from '../../../../Context/HomeContext';
import { ToastContainer, toast } from 'react-toastify';
import LoadingOverlay from '../../../LoadingOverlay/LoadingOverlay';

function EditCounterForm() {
    const [selectedStatus, setSelectedStatus] = useState(0);
    const { isCounterById, getCounterById } = useHome()
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        getCounterById(id);
    }, []);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'title': document.getElementById('title').value,
                'number': document.getElementById('counter').value,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/counter/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Counter updated successfully!', {
                onClose: () => {
                    navigate(`/Home/AllCounters/${isCounterById?.data.lang_id == 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }
    };

    return (
        <div>
            <ToastContainer autoClose={2000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {(loading || !isCounterById) ? <LoadingOverlay /> :
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
                    <div style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Title <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'Title'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isCounterById?.data?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Counter <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'number'}
                            id={'counter'}
                            name={'Counter'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type counter here`}
                            defaultValue={isCounterById?.data?.number}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />
                        <div>
                            <button className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                                onClick={handleSaveClick}
                                disabled={loading}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditCounterForm