import React, { useEffect } from 'react';
import delet from '../../../../Assets/Images/delete.svg';
import edit from '../../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import { Link, useParams } from 'react-router-dom';
import { useHome } from '../../../../Context/HomeContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from '../../../LoadingOverlay/LoadingOverlay';
import DeletModal from '../../../DeleteModel/DeletModal';

function AllBannersTable() {
  const { getBanner, isBanner, deleteBanner } = useHome();
  const { lang } = useParams();

  useEffect(() => {
    getBanner(lang);
  }, []);

  const handleDeleteBanner = async (id) => {
    try {
      await deleteBanner(id, lang);
      toast.success("Banner deleted successfully!");
    } catch (error) {
      toast.error("Error deleting banner!");
      console.error("Error deleting banner:", error);
    }
  };

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      <ToastContainer autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
      {!isBanner && <LoadingOverlay />} {/* Display loading overlay */}
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '15%' }}>SN</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Image</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Name</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '15%' }}>Order</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Status</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isBanner?.data?.map((banner, index) => (
            <tr key={index}>
              <td className='p-5 text-start' style={{ width: '15%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '20%' }}><img src={banner.image_url} alt="" className='w-[250px] h-[125px] rounded-xl' /></td>
              <td className='p-5 text-start' style={{ width: '20%' }}>{banner.title}</td>
              <td className='p-5 text-start' style={{ width: '15%' }}>{banner.order}</td>
              <td className='p-5 text-start' style={{ width: '20%' }}>
                <div className={banner.status == 1 ?
                  'bg-[#0966AE] text-[#fff] p-2 w-[75px] rounded-md border text-center' :
                  'bg-[#ffc107] p-2 w-[75px] rounded-md border text-center'}>
                  {banner.status == 1 ? "Publish" : "Draft"}
                </div></td>
              <td className='p-5 text-center' style={{ width: '20%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/Home/EditBanner/${banner.id}`}>
                    <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                  <Popup
                    trigger={
                      <img src={delet} alt="" className='bg-[#AF1F24] p-2 w-[40px] cursor-pointer rounded-md border' />
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <DeletModal close={close} id={banner.id} onDelete={() => handleDeleteBanner(banner.id)} />
                      </div>
                    )}
                  </Popup>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllBannersTable;