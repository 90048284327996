import axios from "axios";
import { createContext, useContext, useState } from "react";
import { newBaseUrl } from "../Consts"
import NewCategoryForm from "../Components/Services/NewCategory/NewCategoryForm";

export const ServicesContext = createContext();
export const useServices = () => {
    return useContext(ServicesContext);
};

export default function ServicesContextProvider({ children }) {

    const [isServices, setIsServices] = useState()
    const [isServiceById, setIsServiceById] = useState()
    const [isServicesCategory, setIsServicesCategory] = useState()
    const [isServicesCategoryById, setIsServicesCategoryById] = useState()

    async function getServices(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/services`, { headers });
            setIsServices(res?.data)
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getServiceById(id) {
        setIsServiceById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/service/${id}`, { headers });
            setIsServiceById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteService(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/service/${id}`, { headers });
            // setIsServices(res?.data);
            // console.log(res?.data?.data);
            getServices(lang)
        } catch (err) {
            console.error(err);
        }
    }

    async function getServicesCategory(lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Content-Language': lang,
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/services-category`, { headers });
            setIsServicesCategory(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getServicesCategoryById(id) {
        setIsServicesCategoryById()
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.get(`${newBaseUrl}/api/admin/services-category/${id}`, { headers });
            setIsServicesCategoryById(res?.data);
            console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function deleteServicesCategory(id, lang) {
        try {
            const headers = {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            };
            const res = await axios.delete(`${newBaseUrl}/api/delete/services-category/${id}`, { headers });
            // setIsServices(res?.data);
            // console.log(res?.data?.data);
            getServicesCategory(lang)
        } catch (err) {
            console.error(err);
            throw err; // Propagate the error to the calling function
        }
    }

    return (
        <ServicesContext.Provider value={{
            getServicesCategory, getServices, deleteServicesCategory, getServicesCategoryById, getServiceById, deleteService,
            isServicesCategoryById, isServicesCategory, isServices, isServiceById
        }}>
            {children}
        </ServicesContext.Provider>
    );
}
