import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import SideBar from "../Components/SideBar/SideBar";
import Header from "../Components/Header/Header";

function Layout() {
  const location = useLocation();
  
  const getPageTitle = () => {
    // Extract the second part from the pathname
    const parts = location.pathname.split('/');
    // Check if the parts array has at least two elements
    // Return the second element if it exists, otherwise return an empty string
    return parts.length >= 2 ? parts[1] : '';
  };
  
  const getPageTitle2 = () => {
    // Extract the third part from the pathname
    const parts = location.pathname.split('/');
    // Check if the parts array has at least three elements
    // Return the third element if it exists, otherwise return an empty string
    return parts[2] === 'en' ? "" : parts.length >= 3 ? " - " + parts[2].split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '): 
     "";
  };
  
  return (
    <div className="flex">
      {/* Sidebar fixed to the left, stays in place */}
      <div className="fixed top-0 left-0 w-[250px] h-screen bg-[#f4f5f7]">
        <SideBar />
      </div>
    
      {/* Content section with margin-left to avoid overlapping with the sidebar */}
      <div className="ml-[250px] w-full">
        {/* Header fixed at the top */}
        <div className="fixed top-0 w-full py-3 shadow px-8 bg-[#fff] z-10">
          {/* Pass the page title to the Header component */}
          <Header pageTitle={`${getPageTitle()}${getPageTitle2()}`} />
        </div>
  
        {/* Content section with margin-top to account for the fixed header */}
        <div className="mt-[80px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
  
  
}

export default Layout;
