import React, { useEffect, useState } from 'react'
import delet from '../../../Assets/Images/delete.svg';
import edit from '../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import Pagination from '../../Pagination/Pagination';
import { Link, useParams } from 'react-router-dom';
import { useNews } from '../../../Context/NewsContext';
import DeletModal from '../../DeleteModel/DeletModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function AllNewsTable() {
    const { getNews, isNews, deletePost } = useNews();
    const { lang } = useParams();

    useEffect(() => {
        getNews(lang);
    }, []);

    const handleDeletePost = async (id) => {
        try {
            await deletePost(id, lang);
            toast.success("Post deleted successfully!");
        } catch (error) {
            toast.error("Error deleting post!");
            console.error("Error deleting post:", error);
        }
    };

    return (
        <div className='bg-[#fff] rounded-2xl p-3'>
            <ToastContainer autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {!isNews && <LoadingOverlay />} {/* Display loading overlay */}
            <table className='w-full border-collapse mb-6'>
                <thead>
                    <tr className='p-2'>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '10%' }}>SN</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '25%' }}>Image</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '25%' }}>Name</th>
                        {/* <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '25%' }}>Category</th> */}
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '25%' }}>Status</th>
                        <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '25%' }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {isNews?.data?.map((news, index) => (
                        <tr key={index}>
                            <td className='p-5 text-start' style={{ width: '10%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
                            <td className='p-5 text-start' style={{ width: '25%' }}><img src={news.image_url} alt="" className='w-[125px] h-[125px] rounded-xl' /></td>
                            <td className='p-5 text-start' style={{ width: '25%' }}>{news.title}</td>
                            {/* <td className='p-5 text-start' style={{ width: '25%' }}>{news.category?.name}</td> */}
                            <td className='p-5 text-start' style={{ width: '25%' }}>
                                <div className={news.status == 1 ?
                                    'bg-[#0966AE] text-[#fff] p-2 w-[75px] rounded-md border text-center' :
                                    'bg-[#ffc107] p-2 w-[75px] rounded-md border text-center'}>
                                    {news.status == 1 ? "Publish" : "Draft"}
                                </div></td>
                            <td className='p-5 text-center' style={{ width: '25%' }}>
                                <div className='flex gap-2 items-center'>
                                    <Link to={`/News/EditPost/${news.id}`}>
                                        <div className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border'>
                                            <img src={edit} alt="" />
                                        </div>
                                    </Link>
                                    <Popup
                                        trigger={
                                            <div className='bg-[#AF1F24] p-2 w-[40px] cursor-pointer rounded-md border'>
                                                <img src={delet} alt="" />
                                            </div>
                                        }
                                        modal
                                        nested
                                    >
                                        {(close) => (
                                            <div className="modal">
                                                <DeletModal close={close} id={news.id} onDelete={() => handleDeletePost(news.id)} />
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AllNewsTable