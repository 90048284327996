import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useServices } from '../../../Context/ServicesContext';

function Btn() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const { getServicesCategory } = useServices();
  const navigate = useNavigate();
  const { lang } = useParams();

  const handleLanguageChange = (e) => {
    const language = e.target.value;
    setSelectedLanguage(language);
    navigate(`/Services/Categories/${language}`, { replace: true });
    getServicesCategory(language);
  };
  return (
    <div className='w-full flex flex-row items-center justify-between'>
      <select
        name="language"
        id="language"
        value={lang}
        onChange={handleLanguageChange} // Use handleLanguageChange function
        className='w-[150px] border rounded-lg px-2 py-1 pr-2'
      >
        <option value="en">English</option>
        <option value="ar">Arabic</option>
      </select>
      <Link to={"/Services/NewCategory"}>
        <div className='text-[20px] font-[200] text-[#fff] px-5 rounded-3xl py-2 bg-[#0966AE] font-[Cairo]'>New Category</div>
      </Link>
    </div>
  )
}

export default Btn