import React, { useEffect, useState } from 'react'
import delet from '../../../../Assets/Images/delete.svg';
import edit from '../../../../Assets/Images/edit.svg';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import { useHome } from '../../../../Context/HomeContext';
import LoadingOverlay from '../../../LoadingOverlay/LoadingOverlay';

function AllSocialLinksTable() {
  const { getSocialLinks, isSocialLinks } = useHome();

  useEffect(() => {
    getSocialLinks();
  }, []);

  return (
    <div className='bg-[#fff] rounded-2xl p-3'>
      {!isSocialLinks && <LoadingOverlay />} {/* Display loading overlay */}
      <table className='w-full border-collapse mb-6'>
        <thead>
          <tr className='p-2'>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '20%' }}>SN</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Name</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Type</th>
            <th className='text-[16px] font-[500] text-[#0966AE] font-[Cairo] py-2 px-5 text-start' style={{ width: '30%' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {isSocialLinks?.data?.map((SocialLink, index) => (
            <tr key={index}>
              <td className='p-5 text-start' style={{ width: '20%' }}>{String(index + 1).padStart(2, '0') + "."}</td>
              <td className='p-5 text-start' style={{ width: '30%' }}>{SocialLink.name}</td>
              <td className='p-5 text-start' style={{ width: '30%' }}>{SocialLink.type}</td>
              <td className='p-5 text-center' style={{ width: '30%' }}>
                <div className='flex gap-2 items-center'>
                  <Link to={`/Home/EditSocialLink/${SocialLink.id}`}>
                    <img src={edit} alt="" className='bg-[#148342] p-2 w-[40px] cursor-pointer rounded-md border' />
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AllSocialLinksTable