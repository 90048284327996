import React, { useEffect, useState } from 'react';
import axios from "axios";
import { newBaseUrl } from "../../../Consts";
import { useNavigate, useParams } from 'react-router-dom';
import { useHome } from '../../../Context/HomeContext';
import { ToastContainer, toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';
import photo from "../../../Assets/Images/Vector.svg";
import edit from '../../../Assets/Images/edit.svg'; // Import the SVG file

function EditPageBannerForm() {
    const { isPageBannerById, getPageBannerById } = useHome();
    const [selectedFile, setSelectedFile] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPageBannerById(id);
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const maxSizeInBytes = 512 * 1024; // 512KB

            // Validate file size
            if (file.size > maxSizeInBytes) {
                alert("File size exceeds 512KB. Please upload a smaller file.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // Validate file type (jpg, jpeg, png)
            const allowedTypes = ["image/jpeg", "image/png"];
            if (!allowedTypes.includes(file.type)) {
                alert("Only JPG, JPEG, or PNG files are allowed.");
                event.target.value = null; // Reset input field
                return; // Exit the function
            }

            // If all validations pass, update state with selected file
            setSelectedFile(file);
        }
    };

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'image': selectedFile,
            };
            const response = await axios.post(`${newBaseUrl}/api/update/page-banner/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Banner updated successfully!', {
                onClose: () => {
                    navigate(`/Pages/PagesBanners`, { replace: true });
                    setLoading(false);
                },
            });
        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }
    };

    return (
        <div>
            <ToastContainer autoClose={3000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {(loading || !isPageBannerById) ? <LoadingOverlay /> :
                <div className="flex flex-col justify-start bg-[#fff] rounded-3xl py-5 px-[50px]">
                    <div className="relative w-full h-[200px] md:h-[200px] lg:h-[250px] mt-5 xl:h-[350px]"
                        data-aos="fade-in"
                        data-aos-easing="linear"
                        data-aos-duration="1000"
                        data-aos-delay="500">
                        <div htmlFor="fileInput" className="flex flex-col items-center justify-center w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] object-cover rounded-md">
                            <img src={isPageBannerById?.data.image_url != null && selectedFile == null ? isPageBannerById?.data.image_url :
                                isPageBannerById?.data.image_url != null && selectedFile != null ? URL.createObjectURL(selectedFile) : photo}
                                alt="File Upload"
                                className={selectedFile == null && isPageBannerById?.data.image_url == null ? "w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] object-cover rounded-md" : "w-full h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] object-cover rounded-md"}
                            />
                            <div className="absolute inset-0 bg-[#00000060] h-[200px] md:h-[200px] lg:h-[250px] xl:h-[350px] rounded-md"></div>
                            <div className="absolute flex items-center top-5 right-5">
                                {/* Hidden File Input */}
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="hidden"
                                    accept="image/jpeg, image/png"
                                    onChange={handleFileChange}
                                />

                                {/* Icon and Label */}
                                <label
                                    htmlFor="fileInput"
                                    className="flex items-center justify-between w-full p-2 cursor-pointer"
                                >
                                    <img src={edit} alt="Edit Icon" className="w-6 h-6 mr-2" />
                                </label>
                            </div>
                        </div>
                        <div
                            className="lg:about-title absolute inset-[2rem] lg:inset-[4rem] flex flex-col justify-center items-start"
                        >
                            <h1 className="text-[#fff] drop-shadow-text font-[700]"
                                style={{ fontSize: "clamp(2rem, 3vw, 4rem)" }}>
                                {isPageBannerById?.data?.page}
                            </h1>
                        </div>
                    </div>
                    <div>
                        <button
                            className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-5 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                            disabled={loading}
                        >
                            Save
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditPageBannerForm;
