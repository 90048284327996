import React, { useEffect, useState } from 'react'
import axios from "axios";
import { newBaseUrl } from "../../../Consts"
import { useNavigate, useParams } from 'react-router-dom';
import { useCareers } from '../../../Context/CareersContext';
import { toast, ToastContainer } from 'react-toastify';
import LoadingOverlay from '../../LoadingOverlay/LoadingOverlay';

function EditCareerForm() {
    const [selectedStatus, setSelectedStatus] = useState(null);
    const { isCareerById, getCareerById } = useCareers()
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        getCareerById(id).then((data) => {

        }).catch((error) => {
            console.error('Error fetching Career by ID:', error);
        });
    }, []);

    useEffect(() => {
        if (isCareerById?.data?.status) {
            setSelectedStatus(isCareerById?.data?.status);
        }

    }, [isCareerById]);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            const body = {
                'lang_id': isCareerById?.data?.lang_id,
                'title': document.getElementById('title').value,
                'excerpt': document.getElementById('excerpt').value,
                'details': document.getElementById('details').value,
                'location': document.getElementById('location').value,
                'position': document.getElementById('position').value,
                'emp_status': document.getElementById('emp_status').value,
                'skills': document.getElementById('skills').value,
                'education': document.getElementById('education').value,
                'status': selectedStatus,
            };

            const response = await axios.post(`${newBaseUrl}/api/update/career/${id}`, body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            });
            toast.success('Career updated successfully!', {
                onClose: () => {
                    navigate(`/Careers/AllCareers/${isCareerById?.data?.lang_id === 1 ? "en" : "ar"}`, { replace: true });
                    setLoading(false);
                },
            });
            // console.log(res);
        } catch (err) {
            // Check if there are validation errors in the response
            if (err.response && err.response.data && err.response.data.errors) {
                // Loop through each error message and display it
                const errors = err.response.data.errors; // Assuming `errors` is an object or array
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        errors[key].forEach((message) => {
                            toast.error(message); // Display each error message
                        });
                    }
                }
            } else if (err.response && err.response.data && err.response.data.message) {
                // Display a single error message if available
                toast.error(err.response.data.message);
            } else {
                // Handle other errors
                toast.error("An unexpected error occurred.");
            }
            setLoading(false)
        }

    };

    return (
        <div>
            <ToastContainer autoClose={4000} pauseOnFocusLoss={false} pauseOnHover={false} />
            {(loading || !isCareerById) ? <LoadingOverlay /> :
                <div className="flex justify-start bg-[#fff] rounded-3xl py-[50px] px-[50px] h-[100%]">
                    <div className="scrollable-content px-[50px]" style={{ width: '100%' }}>
                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >

                            Title <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'title'}
                            name={'Title'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type title here`}
                            defaultValue={isCareerById?.data?.title}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Location <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'location'}
                            name={'Location'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Location here`}
                            defaultValue={isCareerById?.data?.location}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Position <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'position'}
                            name={'Position'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Position here`}
                            defaultValue={isCareerById?.data?.position}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Employment Status <span className="text-red-500">*</span>
                        </label>

                        <input
                            type={'text'}
                            id={'emp_status'}
                            name={'EmpStatus'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Employment Status here`}
                            defaultValue={isCareerById?.data?.emp_status}
                            style={{
                                paddingBottom: "8px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Excerpt <span className="text-red-500">*</span>
                        </label>

                        <textarea
                            id={'excerpt'}
                            name={'Excerpt'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type excerpt here`}
                            defaultValue={isCareerById?.data?.excerpt}
                            style={{
                                height: "120px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Job Details <span className="text-red-500">*</span>
                        </label>

                        <textarea
                            id={'details'}
                            name={'Details'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Job Details here`}
                            defaultValue={isCareerById?.data?.details}
                            style={{
                                height: "120px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Skills <span className="text-red-500">*</span>
                        </label>

                        <textarea
                            id={'skills'}
                            name={'Skills'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Skills here`}
                            defaultValue={isCareerById?.data?.skills}
                            style={{
                                height: "120px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Education <span className="text-red-500">*</span>
                        </label>

                        <textarea
                            id={'education'}
                            name={'Education'}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 p-2 w-full h-[40px] border-[#DFEAF2] custom-placeholder-style"
                            placeholder={`Type Education here`}
                            defaultValue={isCareerById?.data?.education}
                            style={{
                                height: "120px",
                            }}
                        />

                        <label
                            className="block text-start text-[16px] font-[400] font-[Cairo] pb-1"
                        >
                            Status <span className="text-red-500">*</span>
                        </label>

                        <select
                            name="Status"
                            id="status"
                            value={selectedStatus}
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                            className="border rounded-lg text-[13px] font-[400] font-[Cairo] mb-2 px-2 w-full h-[40px] border-[#DFEAF2] text-[#718EBF]"
                        >
                            <option value="1">Publish</option>
                            <option value="2">Draft</option>
                        </select>
                        <button
                            className="bg-[#0966AE] text-[#fff] rounded-lg py-2 my-3 w-[200px] text-[20px] font-[Cairo]"
                            onClick={handleSaveClick}
                            disabled={loading}
                        >
                            Save
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default EditCareerForm