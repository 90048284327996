import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useProducts } from '../../../Context/ProductsContext';

function Btn() {
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const { getProducts } = useProducts();
    const navigate = useNavigate();
    const { lang } = useParams();
  
    const handleLanguageChange = (e) => {
      const language = e.target.value;
      setSelectedLanguage(language);
      navigate(`/Products/AllProducts/${language}`, { replace: true });
      getProducts(language);
    };
  
    return (
      <div className='w-full flex flex-row items-center justify-between'>
        <select
          name="language"
          id="language"
          value={lang}
          onChange={handleLanguageChange}
          className='w-[150px] border rounded-lg px-2 py-1 pr-2'
        >
          <option value="en">English</option>
          <option value="ar">Arabic</option>
        </select>
        <Link to={"/Products/NewProduct"}>
          <div className='text-[20px] font-[200] text-[#fff] px-5 rounded-3xl py-2 bg-[#0966AE] font-[Cairo]'>New Product</div>
        </Link>
      </div>
    )
  }

export default Btn